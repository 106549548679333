<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :mask-closable="false"
    centered
    :width="600"
    class="model"
    @ok="handleOk"
    style="top: 20px"
    @cancel="handleCancel"
  >
    <a-form>
      <a-form-item label="奖品名称" v-bind="validateInfos.prizeName">
        <a-input v-model:value="form.prizeName" placeholder="请输入奖品名称" />
      </a-form-item>
      <a-form-item label="奖品数量" v-bind="validateInfos.prizeNum">
        <a-input-number
          :min="1"
          :formatter="format"
          :parser="parse"
          v-model:value="form.prizeNum"
          placeholder="请输入奖品数量"
          style="width: 200px"
        />
      </a-form-item>
      <a-form-item label="是否未真实奖品" v-bind="validateInfos.realPrizes">
        <a-switch
          v-model:checked="form.realPrizes"
          checked-children="是"
          un-checked-children="否"
          :checked-value="Number(1)"
          :un-checked-value="Number(0)"
        />
      </a-form-item>
      <a-form-item label="奖品图片" v-bind="validateInfos.prizeImg">
        <iss-image-upload
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
          v-model:value="form.prizeImg"
        ></iss-image-upload>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, toRaw, toRefs, watch } from 'vue';
import { Form, InputNumber, Switch } from 'ant-design-vue';
import imageUpload from '@/components/imageUpload';
import { useRoute } from 'vue-router';
// import agendaApi from '@/api/agenda';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AInputNumber: InputNumber,
    ASwitch: Switch,
    IssImageUpload: imageUpload,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const route = useRoute();
    // const campaignId = route.params.campaignId;
    // const editorRef = ref();
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      prizeName: '',
      prizeNum: 0,
      categoryId: route.params.id,
      prizeImg: '',
    });
    const state = reactive({});
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      prizeName: [required, { max: 30, message: '抽奖名称不能超过30个字符' }],
      prizeNum: [{ ...required, type: 'number' }],
      prizeImg: [required],
    });

    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
        }
      }
    );
    return {
      format(value) {
        // 将输入值转换为整数并返回
        return value ? `${parseInt(value, 10)}` : '';
      },
      parse(value) {
        // 将输入值解析为整数并返回
        return value ? parseInt(value, 10) : null;
      },
      ...toRefs(state),
      form,
      route,
      validateInfos,
      handleCancel: () => context.emit('update:visible', false),
      handleOk: () => {
        validate().then(() => {
          form.prizeNum = form.prizeNum === 0 ? 1 : form.prizeNum;
          context.emit('fnOk', toRaw(form));
        });
      },
    };
  },
};
</script>
<style scoped>
/* .model {
  max-height: 600px;
} */
</style>
