'use strict';

import http from '@/utils/http';

export default {
  // 奖品分页
  pageUrl: '/prize/page',
  // 新增资料
  add(code, data) {
    return http.post('/prize', data, { code });
  },
  // 编辑资料
  update(code, data) {
    return http.put('/prize', data, { code });
  },
  // 删除资料
  delete(code, params) {
    return http.delete('/prize', { params, code });
  },
  audit(code, data) {
    return http.put('/prize/audit', data, { code });
  },
  push(code, data) {
    return http.put('/prize/audit', data, { code });
  },
  prizePublish(code, data) {
    return http.post('/prize-category/prizeDraw', data, {
      code,
    });
  },
};
